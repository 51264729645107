import Image from 'next/image'
import phone1 from '../../public/phone-1.png'
import phone2 from '../../public/phone-2.png'
import { NextPageWithLayout } from './_app'
import Layout from '../components/layout'

function PhoneImage (): JSX.Element {
  return (
    <div className="basis-1/2 grid grid-cols-10 grid-rows-6">
      <div className="col-start-1 col-span-7 row-start-1 row-span-5 z-10">
        <Image src={phone1} alt="Phone" />
      </div>
      <div className="col-start-4 col-span-full row-start-2 row-span-full hover:z-20">
        <Image src={phone2} alt="Phone" />
      </div>
    </div>
  )
}

const Home: NextPageWithLayout = () => {
  return (
    <section className="px-4 mt-4 sm:my-4">
      <div className="flex flex-col sm:flex-row justify-between items-center mx-auto max-w-4xl">
        <div className="basis-1/2 text-center sm:text-left">
          <div className="font-bold text-2xl" style={{ wordBreak: 'keep-all' }}>우리 동네 소식!</div>
          <div className="font-bold text-2xl" style={{ wordBreak: 'keep-all' }}>더 편리하게</div>
          <div className="font-bold text-2xl" style={{ wordBreak: 'keep-all' }}>알아볼 수 있는 방법은 없을까?</div>
          <div className="text-base mt-4" style={{ wordBreak: 'keep-all' }}>
            전대전 앱에서 다양한 지역 소식을 자유롭게 나눠 보세요! 페이스북 &lt;대신 전해드립니다&gt;
            지역 페이지 제보함과 연동해 다양한 채널에서 더욱 편리하게 필요한 정보를 나눌 수 있어요.
          </div>
        </div>
        <PhoneImage />
      </div>
    </section>
  )
}

Home.getLayout = (page, options) => {
  const device = options?.device ?? { isMobileOnly: false, isIOS: false, isAndroid: false }
  return (
    <Layout device={device}>
      {page}
    </Layout>
  )
}

export default Home
