import 'tailwindcss/tailwind.css'
import Head from 'next/head'
import Link from 'next/link'
import { useState } from 'react'
import Image from 'next/image'
import logo from '../../public/logo.png'
import appStore from '../../public/app-store.png'
import playStore from '../../public/play-store.png'
import { useRouter } from 'next/router'

function Header (): JSX.Element {
  const [showDownload, setShowDownload] = useState(false)
  const router = useRouter()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { utm_source, utm_medium }: {utm_source?: string, utm_medium?: string} = router.query

  return (
    <header className="border-b sticky top-0 bg-white py-3.5 px-4 z-30">
      <div className="max-w-3xl mx-auto py-1 flex flex-row items-center justify-between">
        <div className="max-h-10">
          <Link href="/">
            <Image src={logo} width={169} height={40} objectFit="contain" quality="100" alt="Logo" priority={true} />
          </Link>
        </div>
        {/* 넓은 화면 */}
        <div className="mobile-portrait:hidden -mobile-portrait:flex flex-row items-center">
          <a href={`https://apps.apple.com/app/apple-store/id1603189650?pt=122829483&mt=8&ct=${utm_source ?? 'undefined'}-${utm_medium ?? 'undefined'}`}>
            <div className="max-h-10">
              <Image src={appStore} width={120} height={40} quality="100" alt="Apple App Store" priority={true} />
            </div>
          </a>
          <a href={`market://details?id=com.jdjeon&referrer=${encodeURIComponent(`utm_srouce=${utm_source ?? 'undefined'}&utm_medium=${utm_medium ?? 'undefined'}`)}`} className="ml-2">
            <div className="max-h-10">
              <Image src={playStore} width={120} height={40} quality="100" alt="Google Play Store" priority={true} />
            </div>
          </a>
        </div>
        {/* 좁은 화면 */}
        <div className="-mobile-portrait:hidden">
          <div className="p-2 border rounded cursor-pointer" onClick={() => setShowDownload(!showDownload)}>다운로드</div>
          <ul className={`absolute mt-2 -ml-12 bg-white min-w-fit ${!showDownload ? 'hidden' : ''}`}>
            <li>
              <a href={`https://apps.apple.com/app/apple-store/id1603189650?pt=122829483&mt=8&ct=${utm_source ?? 'undefined'}-${utm_medium ?? 'undefined'}`}>
                <div className="max-h-10">
                  <Image src={appStore} width={120} height={40} quality="100" alt="Apple App Store" priority={true} />
                </div>
              </a>
            </li>
            <li className="-mt-px">
              <a href={`market://details?id=com.jdjeon&referrer=${encodeURIComponent(`utm_srouce=${utm_source ?? 'undefined'}&utm_medium=${utm_medium ?? 'undefined'}`)}`}>
                <div className="max-h-10">
                  <Image src={playStore} width={120} height={40} quality="100" alt="Google Play Store"
                         priority={true} />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

function Footer (): JSX.Element {
  return (
    <footer className="bg-gray-700 text-zinc-100 px-4">
      <div className="max-w-3xl mx-auto py-1 flex flex-row items-center justify-between">
        <div className="shrink-0 mt-4">
          <Link href="/">
            <Image src={logo} width={169} height={40} objectFit="contain" quality="100" alt="Logo" priority={true} />
          </Link>
        </div>
        <div className="flex flex-row items-center justify-between flex-wrap">
          <div className="ml-5 my-0.5">
            <Link href="/policies/service">
              이용약관
            </Link>
          </div>
          <div className="ml-5 my-0.5">
            <Link href="/policies/privacy">
              <strong>개인정보처리방침</strong>
            </Link>
          </div>
          <div className="ml-5 my-0.5">
            <Link href="/policies/location">
              위치기반서비스 이용약관
            </Link>
          </div>
        </div>
      </div>
      <div className="max-w-3xl mx-auto py-4 mt-4">
        <div>주식회사 휴비즈</div>
        <div>서울특별시 성동구 아차산로17길 49, RB101호, RB102호, RB103호(성수동2가, 생각공장 데시앙플렉스)</div>
        <div>사업자번호: 420-86-00886</div>
      </div>
    </footer>
  )
}

function Modal ({ isMobileOnly, isIOS }: Device): JSX.Element {
  const [showModal, setShowModal] = useState(true)
  const router = useRouter()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { utm_source, utm_medium }: {utm_source?: string, utm_medium?: string} = router.query

  if (!isMobileOnly) return <></>

  const storeLink = isIOS
    ? `https://apps.apple.com/app/apple-store/id1603189650?pt=122829483&mt=8&ct=${utm_source ?? 'undefined'}-${utm_medium ?? 'undefined'}`
    : `market://details?id=com.jdjeon&referrer=${encodeURIComponent(`utm_srouce=${utm_source ?? 'undefined'}&utm_medium=${utm_medium ?? 'undefined'}`)}`

  return (
    <div
      className={`overflow-hidden z-40 bg-black/60 fixed inset-0${!showModal ? 'hidden' : ''}`}
      onClick={() => setShowModal(false)}
    >
      <div className="flex flex-col py-4 px-8 bg-white absolute bottom-0 left-0 right-0 z-50 rounded-t-3xl">
        <div className="flex justify-center items-center my-2">
          <img src="/app-icon.png" alt="App Icon" className="w-20 h-20" />
          <div className="ml-4 font-black text-lg">
            <div>전대전 앱에서 더욱 편리하게</div>
            <div>우리 동네 소식을 알아보세요!</div>
          </div>
        </div>
        <a href={storeLink}>
          <div className="flex justify-center items-center my-2 bg-app-blue-background text-app-blue h-12">
            앱 다운로드
          </div>
        </a>
        <div className="flex justify-center items-center my-2 text-neutral-400">웹으로 보기</div>
      </div>
    </div>
  )
}

export interface Device {
  isMobileOnly: boolean
  isIOS: boolean
  isAndroid: boolean
}
interface LayoutProps {
  children: JSX.Element
  device: Device
}
export default function Layout ({ children, device }: LayoutProps): JSX.Element {
  return (
    <div className="flex flex-col h-screen justify-between">
      <Head>
        <title>전국대신전해드립니다</title>
        <meta name="description" content="전국대신전해드립니다, 전대전 앱에서 다양한 지역 소식을 자유롭게 나눠 보세요! 페이스북 <대신 전해드립니다> 지역 페이지 제보함과 연동해 다양한 채널에서 더욱 편리하게 필요한 정보를 나눌 수 있어요." />
        <link rel="icon" href="/favicon.ico" />
        <style
          dangerouslySetInnerHTML={{
            __html: `
              html,
              body {
                padding: 0;
                margin: 0;
                font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
                Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
              }

              a {
                color: inherit;
                text-decoration: none;
              }

              * {
                box-sizing: border-box;
              }
            `
          }}
        />
      </Head>
      <Header />
      <main className="mb-auto">{children}</main>
      <Footer />
      <Modal {...device} />
    </div>
  )
}
